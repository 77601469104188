export const BlankSlate = ({
  description,
  title,
}: {
  description: React.ReactNode
  title: React.ReactNode
}) => (
  <div className="text-white text-center p-10 space-y-6">
    <h1 className="text-3xl leading-8 font-semibold tracking-tight text-white sm:text-4xl">
      {title}
    </h1>
    <p className="text-lg">{description}</p>
  </div>
)
