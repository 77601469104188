import { EventDetail } from './events.types'
import { Cover as allthatglittersisntgold2021cover } from '../assets/images/events/allthatglittersisntgold2021'
import { Cover as bankholidaysunday2019cover } from '../assets/images/events/bankholidaysunday2019'
import { Hero as bankholidaysunday2019hero } from '../assets/images/events/bankholidaysunday2019'
import { Cover as bottomlessbrunch2019cover } from '../assets/images/events/bottomlessbrunch2019'
import { Hero as bottomlessbrunch2019hero } from '../assets/images/events/bottomlessbrunch2019'
import { Cover as dayparty2019cover } from '../assets/images/events/dayparty2019'
import { Hero as dayparty2019hero } from '../assets/images/events/dayparty2019'
import { Cover as dayparty2021cover } from '../assets/images/events/dayparty2021'
import { Cover as endofsummerparty2020cover } from '../assets/images/events/endofsummerparty2020'
import { Hero as endofsummerparty2020hero } from '../assets/images/events/endofsummerparty2020'
import { Cover as goldenbeeapril2022cover } from '../assets/images/events/goldenbeeapril2022'
import { Hero as goldenbeeapril2022hero } from '../assets/images/events/goldenbeeapril2022'
import { Cover as goldenbeeoctober2021cover } from '../assets/images/events/goldenbeeoctober2021'
import { Hero as goldenbeeoctober2021hero } from '../assets/images/events/goldenbeeoctober2021'
import { Cover as goldenbeerooftop2021cover } from '../assets/images/events/goldenbeerooftop2021'
import { Hero as goldenbeerooftop2021hero } from '../assets/images/events/goldenbeerooftop2021'
import { Cover as pinkzebra2020cover } from '../assets/images/events/pinkzebra2020'
import { Hero as pinkzebra2020hero } from '../assets/images/events/pinkzebra2020'
import { Cover as sugarhutmarch2022cover } from '../assets/images/events/sugarhutmarch2022'
import { Cover as terraceparty2020cover } from '../assets/images/events/terraceparty2020'
import { Hero as terraceparty2020hero } from '../assets/images/events/terraceparty2020'

export const events: Array<EventDetail> = [
  {
    id: 'goldenbeeapril2022',
    name: 'Golden Bee April 2022',
    start: '2022-04-24T16:00:00',
    end: '2022-04-24T22:30:00',
    last_entry: '2022-04-24T19:00:00',
    cover_image: goldenbeeapril2022cover,
    hero_image: goldenbeeapril2022hero,
  },
  {
    id: 'sugarhutmarch2022',
    name: 'Sugar Hut',
    start: '2022-03-05T21:00:00',
    end: '2022-03-06T03:00:00',
    last_entry: '2022-03-06T00:00:00',
    minimum_age: 21,
    cover_image: sugarhutmarch2022cover,
  },
  {
    id: 'allthatglittersisntgold2021',
    name: "All That Glitters isn't Gold",
    start: '2021-11-21T17:00:00',
    end: '2021-11-21T23:00:00',
    last_entry: '2021-11-21T19:30:00',
    cover_image: allthatglittersisntgold2021cover,
  },
  {
    id: 'goldenbeeoctober2021',
    name: 'Golden Bee',
    start: '2021-10-17T15:00:00',
    end: '2021-10-17T23:00:00',
    cover_image: goldenbeeoctober2021cover,
    hero_image: goldenbeeoctober2021hero,
  },
  {
    id: 'goldenbeerooftop2021',
    name: 'Golden Bee Rooftop',
    start: '2021-09-19T15:00:00',
    end: '2021-09-19T23:00:00',
    cover_image: goldenbeerooftop2021cover,
    hero_image: goldenbeerooftop2021hero,
  },
  {
    id: 'dayparty2021',
    name: 'NOVA Day Party',
    start: '2021-08-15T12:00:00',
    end: '2021-08-15T22:00:00',
    cover_image: dayparty2021cover,
  },
  {
    id: 'endofsummerparty2020',
    name: 'End of Summer Party',
    start: '2020-09-13T14:00:00',
    end: '2020-09-13T22:00:00',
    cover_image: endofsummerparty2020cover,
    hero_image: endofsummerparty2020hero,
  },
  {
    id: 'terraceparty2020',
    name: 'Terrace Party',
    start: '2020-08-02T14:00:00',
    end: '2020-08-02T22:00:00',
    cover_image: terraceparty2020cover,
    hero_image: terraceparty2020hero,
  },
  {
    id: 'pinkzebra2020',
    name: 'Pink Zebra',
    start: '2020-02-22T20:30:00',
    end: '2020-02-23T03:00:00',
    cover_image: pinkzebra2020cover,
    hero_image: pinkzebra2020hero,
  },
  {
    id: 'dayparty2019',
    name: 'Day Party',
    start: '2019-08-24T15:00:00',
    end: '2019-08-25T00:00:00',
    cover_image: dayparty2019cover,
    hero_image: dayparty2019hero,
  },
  {
    id: 'bottomlessbrunch2019',
    name: 'Bottomless Brunch',
    start: '2019-06-22T15:00:00',
    end: '2019-06-22T19:00:00',
    cover_image: bottomlessbrunch2019cover,
    hero_image: bottomlessbrunch2019hero,
  },
  {
    id: 'bankholidaysunday2019',
    name: 'Bank Holiday Sunday',
    start: '2019-04-21T17:00:00',
    end: '2019-04-22T03:00:00',
    cover_image: bankholidaysunday2019cover,
    hero_image: bankholidaysunday2019hero,
  },
]
