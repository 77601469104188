import { format, isFuture, parseISO } from 'date-fns'
import _ from 'lodash'
import { useHistory } from 'react-router'
import { CardGrid, CardGridItem } from '../components'
import { events } from '../data'
import { formatTime } from '../utils'

export const Events = () => {
  const history = useHistory()

  return (
    <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div className="space-y-12">
        <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 className="text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
            Events
          </h2>
          <p className="text-xl text-gray-300">
            Don't Miss Out On These Amazing Experiences
          </p>
        </div>
        {events.length > 0 && (
          <CardGrid
            dataSource={events}
            renderItem={(event) => {
              const isUpcoming = isFuture(parseISO(event.end))
              return (
                <CardGridItem
                  imageSrc={event.cover_image}
                  title={event.name}
                  extra={
                    <>
                      <p>{format(new Date(event.start), 'EEE MMMM d yyyy')}</p>
                      <p>
                        {formatTime(event.start)} - {formatTime(event.end)}
                        {isUpcoming &&
                          !_.isUndefined(event.last_entry) &&
                          isFuture(new Date(event.last_entry)) &&
                          ` (last entry ${formatTime(event.last_entry)})`}
                      </p>
                      {isUpcoming && !_.isUndefined(event.minimum_age) && (
                        <p>Minimum Age: {event.minimum_age}</p>
                      )}
                    </>
                  }
                  onClick={() => history.push(`/events/${event.id}`)}
                />
              )
            }}
          />
        )}
      </div>
    </div>
  )
}
