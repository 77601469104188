import { bankholidaysunday2019_video } from '../assets/videos'
import { bankholidaysunday2019_thumbnail } from '../assets/videos'
import { bottomlessbrunch2019_video } from '../assets/videos'
import { bottomlessbrunch2019_thumbnail } from '../assets/videos'
import { dayparty2019_video } from '../assets/videos'
import { dayparty2019_thumbnail } from '../assets/videos'
import { goldenbeerooftop2021_video } from '../assets/videos'
import { goldenbeerooftop2021_thumbnail } from '../assets/videos'
import { pinkzebra2020_video } from '../assets/videos'
import { pinkzebra2020_thumbnail } from '../assets/videos'
import { terraceparty2020_video } from '../assets/videos'
import { terraceparty2020_thumbnail } from '../assets/videos'

export const videos = [
  {
    eventId: 'goldenbeerooftop2021',
    path: goldenbeerooftop2021_video,
    thumbnail: goldenbeerooftop2021_thumbnail,
  },
  {
    eventId: 'terraceparty2020',
    path: terraceparty2020_video,
    thumbnail: terraceparty2020_thumbnail,
  },
  {
    eventId: 'pinkzebra2020',
    path: pinkzebra2020_video,
    thumbnail: pinkzebra2020_thumbnail,
  },
  {
    eventId: 'dayparty2019',
    path: dayparty2019_video,
    thumbnail: dayparty2019_thumbnail,
  },
  {
    eventId: 'bottomlessbrunch2019',
    path: bottomlessbrunch2019_video,
    thumbnail: bottomlessbrunch2019_thumbnail,
  },
  {
    eventId: 'bankholidaysunday2019',
    path: bankholidaysunday2019_video,
    thumbnail: bankholidaysunday2019_thumbnail,
  },
]
