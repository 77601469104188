import { format, getMinutes } from 'date-fns'

export const formatTime = (timestamp: string) =>
  format(
    new Date(timestamp),
    `h${getMinutes(new Date(timestamp)) > 0 ? ':m' : ''}${
      ['noon', 'midnight'].includes(format(new Date(timestamp), 'bbb'))
        ? ' '
        : ''
    }bbb`
  )
