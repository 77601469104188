import { SocialMedia } from './SocialMedia'

export const Footer = () => (
  <footer aria-labelledby="footerHeading">
    <h2 id="footerHeading" className="sr-only">
      Footer
    </h2>
    <div className="p-4 sm:p-6 lg:p-8">
      <div className="mt-8 pt-8 md:flex md:items-center md:justify-between">
        <div className="sm:w-max md:w-auto">
          <div className="p-4 border border-gray-500 bg-gray-800 rounded-md sm:space-y-2 md:flex md:items-center md:space-y-0 md:space-x-2">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Join our mailing list
            </h3>
            <form
              className="mt-4 sm:flex sm:mt-0 sm:max-w-md"
              action={process.env.REACT_APP_MAILCHIMP_ACTION}
              method="post"
              target="_blank"
              noValidate
            >
              <label htmlFor="mce-EMAIL" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                autoComplete="email"
                required
                className="appearance-none min-w-0 w-full bg-black border border-transparent rounded-md py-2 px-4 text-base text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-400 focus:border-gray-400 focus:placeholder-gray-400"
                placeholder="Email"
              />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="w-full bg-gray-700 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
        <SocialMedia className="mt-8 md:order-2 sm:hidden" />
        <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">Nova.</p>
      </div>
    </div>
  </footer>
)
