import { format, isPast, parseISO } from 'date-fns'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { HiOutlineXCircle } from 'react-icons/hi'
import ImageGallery from 'react-image-gallery'
import { useParams } from 'react-router'
import { BlankSlate } from '../components'
import { events } from '../data'
import { EventDetail } from '../data/events.types'
import { objectFilter } from '../utils'

const PastEvent = ({ event }: { event: EventDetail }) => {
  const [images, setImages] = useState<
    Array<{ original: string; thumbnail: string }>
  >([])
  const [showCarousel, setShowCarousel] = useState(false)
  const [carouselIndex, setCarouselIndex] = useState(0)

  useEffect(() => {
    import(`../assets/images/events/${event.id}`).then((importedImages) => {
      const originalPaths: Array<string> = Object.values(
        objectFilter(importedImages, ([key]) =>
          key.toLowerCase().startsWith('image')
        )
      )
      const thumbnailPaths: Array<string> = Object.values(
        objectFilter(importedImages, ([key]) =>
          key.toLowerCase().startsWith('thumbnail')
        )
      )
      setImages(
        originalPaths.map((image, index) => ({
          original: image,
          thumbnail: thumbnailPaths[index] || image,
        }))
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 bg-gray-100"></div>
        <div className="relative sm:overflow-hidden">
          <div className="absolute inset-0">
            {!_.isUndefined(event.hero_image) && (
              <img
                className="h-full w-full object-cover object-top"
                src={event.hero_image}
                alt={event.name}
              />
            )}
            <div
              className="absolute inset-0 bg-gray-500"
              style={{ mixBlendMode: 'multiply' }}
            ></div>
          </div>
          <div className="relative px-4 py-32 sm:px-6 sm:py-48 lg:px-8">
            <h1 className="text-center font-semibold tracking-tight">
              <span className="block text-white text-4xl sm:text-5xl lg:text-6xl">
                {event.name.toUpperCase()}
              </span>
              <span className="block text-gray-200 text-2xl sm:text-3xl lg:text-4xl">
                {format(new Date(event.start), 'EEE MMMM d yyyy')}
              </span>
            </h1>
          </div>
        </div>
      </div>
      {images.length > 0 ? (
        <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-10">
          {images.map((image, index) => (
            <div className="aspect-w-1 aspect-h-1">
              <img
                className="border border-transparent h-full w-full object-cover object-top cursor-pointer hover:opacity-70"
                src={image.thumbnail}
                alt=""
                onClick={() => {
                  setShowCarousel(true)
                  setCarouselIndex(index)
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <span className="block mt-8 text-gray-200 text-center text-xl sm:text-2xl lg:text-3xl">
          Photos coming soon...
        </span>
      )}
      <div
        className={`${
          showCarousel ? 'block' : 'hidden'
        } bg-gray-700 bg-opacity-90 fixed inset-0`}
        onClick={() => setShowCarousel(false)}
      >
        <div
          className="flex justify-center py-0.5 text-white hover:text-gray-300 cursor-pointer"
          onClick={() => setShowCarousel(false)}
        >
          <HiOutlineXCircle className="w-10 h-10" />
        </div>
        <ImageGallery
          lazyLoad
          items={images}
          renderItem={(item) => (
            <div
              className="flex items-center justify-center outline-none"
              onClick={() => setShowCarousel(false)}
              style={{ height: 'calc(100vh - 150px)' }}
            >
              <img
                className="object-contain cursor-default"
                src={item.original}
                alt=""
                onClick={(e) => e.stopPropagation()}
                style={{ maxHeight: '100%' }}
              />
            </div>
          )}
          renderThumbInner={(item) => (
            <div
              className="image-gallery-thumbnail-inner"
              onClick={(e) => {
                e.stopPropagation()
                setCarouselIndex(
                  images.findIndex((image) => image.original === item.original)
                )
              }}
              style={{ margin: '-3px', padding: '3px' }}
            >
              <img
                className="image-gallery-thumbnail-image"
                src={item.thumbnail}
                alt=""
              />
            </div>
          )}
          showNav={false}
          showPlayButton={false}
          showFullscreenButton={false}
          startIndex={carouselIndex}
        />
      </div>
    </>
  )
}

const UpcomingEvent = ({ event }: { event: EventDetail }) => (
  <div className="mt-6 overflow-hidden">
    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:py-16">
      <div className="hidden lg:block bg-gray-400 absolute top-0 bottom-0 right-3/4 w-screen"></div>
      <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
        <h3 className="text-3xl leading-8 font-extrabold tracking-tight text-white text-center sm:text-4xl lg:col-start-2 lg:text-left">
          {event.name}
        </h3>
      </div>
      <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
        <div className="relative lg:row-start-1">
          <svg
            className="hidden lg:block absolute top-0 left-0 -mt-20 -ml-20"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-50"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
            />
          </svg>
          <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
            {!_.isUndefined(event.ticket_link) && (
              <div className="flex justify-center">
                <a
                  href={event.ticket_link}
                  target="blank"
                  className="block w-max mx-auto sm:hidden space-y-2 group rounded-3xl bg-red-500 text-white py-4 px-8 sm:text-base lg:text-sm xl:text-base hover:bg-red-600 hover:text-black"
                >
                  <span className="block w-max mx-auto text-lg font-semibold leading-5 uppercase tracking-wide">
                    BOOK NOW
                  </span>

                  <p className="flex items-center w-max mx-auto space-x-2 text-lg">
                    Tickets Available
                  </p>
                </a>
                <a
                  href={event.ticket_link}
                  target="blank"
                  className="hidden sm:inline-flex items-center group text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-400"
                >
                  <span className="px-12 py-2 text-white text-lg font-semibold leading-5 uppercase tracking-wide bg-red-500 rounded-full group-hover:bg-red-600 group-hover:text-black">
                    BOOK NOW
                  </span>
                  <span className="ml-4 text-lg">Tickets Available</span>
                  <svg
                    className="ml-2 w-5 h-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </div>
            )}
            <figure className="mt-6 flex justify-center">
              <img
                className="rounded-lg shadow-lg object-cover object-center"
                src={event.cover_image}
                alt={event.name}
              />
            </figure>
          </div>
        </div>
        <div className="mt-8 lg:mt-0">
          {!_.isUndefined(event.short_description) && (
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg text-gray-400">{event.short_description}</p>
            </div>
          )}
          {!_.isUndefined(event.long_description) && (
            <div className="mt-5 prose prose-indigo text-gray-300 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              {event.long_description}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)

export const Event = () => {
  const { eventId }: { eventId: string } = useParams()
  const event = events.find((e) => e.id === eventId)

  if (_.isUndefined(event)) {
    return (
      <BlankSlate
        title="There's no event here"
        description="We couldn't find the event you were looking for"
      />
    )
  }

  const EventComponent = isPast(parseISO(event.end)) ? PastEvent : UpcomingEvent

  return <EventComponent event={event} />
}
