import _ from 'lodash'

export const CardGridItem = ({
  imageSrc,
  title,
  extra,
  onClick,
}: {
  imageSrc?: string
  title: React.ReactNode
  extra?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLLIElement>
}) => (
  <li
    className={!_.isUndefined(onClick) ? 'hover:opacity-80 cursor-pointer' : ''}
    onClick={onClick}
  >
    <div className="space-y-4">
      {imageSrc && (
        <div className="aspect-w-1 aspect-h-1">
          <img
            className="object-cover shadow-lg rounded-lg"
            src={imageSrc}
            alt=""
          />
        </div>
      )}
      <div className="space-y-2">
        <div className="text-lg leading-6 font-medium space-y-1">
          <h3 className="text-white">{title}</h3>
          {extra && <p className="text-gray-300">{extra}</p>}
        </div>
      </div>
    </div>
  </li>
)
