import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { History } from 'history'

const ScrollToTop = ({
  history,
  children,
}: {
  history: History
  children?: React.ReactNode
}) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}

export default withRouter(ScrollToTop)
