import { useEffect } from 'react'

const EVENT = 'mousedown'

export const useClickAway = (
  ref: React.MutableRefObject<any> | Array<React.MutableRefObject<any>>,
  callback: (event: Event) => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (
        (Array.isArray(ref) &&
          ref.some(
            (r) => !r || !r.current || r.current.contains(event.target)
          )) ||
        (!Array.isArray(ref) &&
          (!ref || !ref.current || ref.current.contains(event.target)))
      ) {
        return
      }
      callback(event)
    }
    document.addEventListener(EVENT, listener)
    return () => {
      document.removeEventListener(EVENT, listener)
    }
  }, [ref, callback])
}
