import { isFuture, parseISO } from 'date-fns'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { events } from '../data'

export const Home = () => {
  const event = _.orderBy(events, 'start', 'desc')[0]
  const isUpcoming = !_.isUndefined(event) && isFuture(parseISO(event.end))
  return (
    <div className="pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className={isUpcoming ? 'lg:grid lg:grid-cols-2 lg:gap-8' : ''}>
          <div
            className={`mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 text-center lg:px-0 lg:flex lg:items-center ${
              isUpcoming ? 'lg:text-left' : ''
            }`}
          >
            <div className="lg:pt-12 lg:pb-36">
              <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                <span className="block">Bringing You</span>
                <span className="block text-gray-400">
                  Bigger and Better Events
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Same Good Vibe.. Same Good Music.. Same Good Energy..
              </p>
            </div>
          </div>
          {isUpcoming && (
            <div className="mt-12 lg:m-0 lg:relative">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                <h2 className="text-center mb-6 text-3xl tracking-tight font-semibold text-white sm:text-4xl lg:text-left lg:mb-0 lg:text-5xl">
                  UPCOMING EVENTS
                </h2>
                <Link to={`/events/${event?.id}`}>
                  <div className="aspect-w-1 aspect-h-1 lg:mt-6 hover:opacity-80">
                    <img
                      className="w-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src={event.cover_image}
                      alt=""
                    />
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
