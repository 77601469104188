import { DynamicObject } from '../index.types'

export const objectFilter = (
  obj: DynamicObject = {},
  filterFunction: ([key, value]: [key: string, value: any]) => boolean
) =>
  Object.assign(
    {},
    ...Object.entries(obj)
      .filter(filterFunction)
      .map(([key, value]) => ({ [key]: value }))
  )
