import { Dialog, Transition } from '@headlessui/react'
import format from 'date-fns/format'
import _ from 'lodash'
import { Fragment, useState } from 'react'
import ReactPlayer from 'react-player'
import { events, videos } from '../data'
import heroImage from '../assets/images/videos.jpg'
import './Videos.scss'

export const Videos = () => {
  const [open, setOpen] = useState(false)
  const [currentVideoPath, setCurrentVideoPath] = useState<string | null>(null)

  return (
    <>
      <div className="space-y-6">
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 bg-gray-100"></div>
          <div className="relative sm:overflow-hidden">
            <div className="absolute inset-0">
              <img
                className="h-full w-full object-cover object-top"
                src={heroImage}
                alt=""
              />
              <div
                className="absolute inset-0 bg-gray-500"
                style={{ mixBlendMode: 'multiply' }}
              ></div>
            </div>
            <div className="relative px-4 py-32 sm:px-6 sm:py-48 lg:px-8">
              <h1 className="text-center font-semibold tracking-tight">
                <span className="block text-white text-4xl sm:text-5xl lg:text-6xl">
                  VIDEOS
                </span>
              </h1>
            </div>
          </div>
        </div>
        <ul className="grid grid-cols-2 gap-x-4 gap-y-8 px-4 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {videos.map((video) => {
            const event = events.find((event) => event.id === video.eventId)
            if (!_.isUndefined(event)) {
              return (
                <li
                  key={video.path}
                  className="relative"
                  onClick={() => {
                    setCurrentVideoPath(video.path)
                    setOpen(true)
                  }}
                >
                  <div className="focus:outline-none group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                    <img
                      src={video.thumbnail}
                      alt=""
                      className="group-hover:opacity-75 object-cover pointer-events-none"
                    />
                    <button
                      type="button"
                      className="absolute inset-0 focus:outline-none"
                    >
                      <span className="sr-only">View {event.name} video</span>
                    </button>
                  </div>
                  <p className="mt-2 block text-sm font-medium text-white truncate pointer-events-none">
                    {event.name}
                  </p>
                  <p className="block text-sm font-medium text-white pointer-events-none">
                    {format(new Date(event.start), 'MMMM d yyyy')}
                  </p>
                </li>
              )
            } else {
              return null
            }
          })}
        </ul>
      </div>
      <Transition.Root
        show={open}
        as={Fragment}
        afterLeave={() => setCurrentVideoPath(null)}
      >
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-black rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <button className="h-0 w-0 absolute right-0 top-0" />
                <ReactPlayer
                  className="focus:outline-none focus:border-opacity-0 focus:ring-opacity-0"
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                      },
                    },
                  }}
                  url={currentVideoPath || ''}
                  width="100%"
                  controls
                  muted
                  playing
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
