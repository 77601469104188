import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Transition } from '@headlessui/react'
import NovaLogo from '../assets/images/nova-logo.png'
import { SocialMedia } from './SocialMedia'
import { useClickAway } from '../utils'

export const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const mobileMenuRef = useRef(null)
  useClickAway(mobileMenuRef, () => setMobileMenuOpen(false))

  return (
    <header className="relative">
      <div className="bg-gray-900 pt-6">
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
          aria-label="Global"
        >
          <div className="flex items-center justify-between flex-1">
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link to="/">
                  <span className="sr-only">NOVA</span>
                  <img className="h-16 w-auto sm:h-20" src={NovaLogo} alt="" />
                </Link>
                <div className="-mr-2 flex items-center md:hidden">
                  <button
                    type="button"
                    className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center bg text-gray-400 hover:bg-gray-800 focus:outline-none"
                    aria-expanded="false"
                    onClick={() => setMobileMenuOpen(true)}
                  >
                    <span className="sr-only">Open main menu</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="hidden space-x-8 md:flex md:ml-10">
                <Link
                  to="/events"
                  className="text-xl font-medium text-white hover:text-gray-300"
                >
                  EVENTS
                </Link>

                <Link
                  to="/videos"
                  className="text-xl font-medium text-white hover:text-gray-300"
                >
                  VIDEOS
                </Link>
                {/* <Link
                  to="#"
                  className="text-xl font-medium text-white hover:text-gray-300"
                >
                  PHOTOS
                </Link> */}
              </div>
            </div>
            <SocialMedia className="hidden md:flex" />
          </div>
        </nav>
      </div>
      <Transition
        show={mobileMenuOpen}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          className="z-10 absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden"
          ref={mobileMenuRef}
        >
          <div className="rounded-lg shadow-md bg-black ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <img className="h-16 w-auto" src={NovaLogo} alt="" />
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  className="bg-black rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-600"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="pt-5 pb-6">
              <div className="px-2 space-y-1">
                <Link
                  to="/events"
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-900"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  EVENTS
                </Link>
                <Link
                  to="/videos"
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-900"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  VIDEOS
                </Link>
                {/* <Link
                  to="#"
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-900"
                   onClick={() => setMobileMenuOpen(false)}
               >
                  PHOTOS
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </header>
  )
}
