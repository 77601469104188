import Cover from './cover.jpeg'
import Hero from './1.jpeg'
import Image1 from './1.jpeg'
import Image2 from './2.jpeg'
import Image3 from './3.jpeg'
import Image4 from './4.jpeg'
import Image5 from './5.jpeg'
import Image6 from './6.jpeg'
import Thumbnail1 from './1t.jpg'
import Thumbnail2 from './2t.jpg'
import Thumbnail3 from './3t.jpg'
import Thumbnail4 from './4t.jpg'
import Thumbnail5 from './5t.jpg'
import Thumbnail6 from './6t.jpg'

export {
  Cover,
  Hero,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Thumbnail1,
  Thumbnail2,
  Thumbnail3,
  Thumbnail4,
  Thumbnail5,
  Thumbnail6,
}
