import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Footer, Header, ScrollToTop } from './components'
import * as Pages from './pages'

export default function App() {
  return (
    <div className="min-h-screen bg-gray-900">
      <div className="relative overflow-hidden">
        <Router>
          <Header />
          <main>
            <ScrollToTop>
              <Switch>
                <Route exact path="/">
                  <Pages.Home />
                </Route>
                <Route exact path="/events">
                  <Pages.Events />
                </Route>
                <Route exact path="/events/:eventId">
                  <Pages.Event />
                </Route>
                <Route exact path="/videos">
                  <Pages.Videos />
                </Route>
                <Route>
                  <Pages.NotFound />
                </Route>
              </Switch>
            </ScrollToTop>
          </main>
        </Router>
        <Footer />
      </div>
    </div>
  )
}
